<header>
  <app-header></app-header>
</header>
<main>
  <router-outlet></router-outlet>
</main>
<footer>
  <app-footer></app-footer>
</footer>

<div [ngClass]="{ 'show-scroll': showScroll }" (click)="scrollToTop()" class="scroll-to-top">
  <img
      src="assets/images/global/arrow-down-double-sharp.svg"
      alt="arrow-down-double"
      width="30"
      height="30"
      class="max-sm:w-[27px] max-sm:h-[27px]"
      />
</div>
