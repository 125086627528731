import { CommonModule, isPlatformBrowser, Location, NgOptimizedImage } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { ItemMenu } from '../../../types/menu';
import { Menu, MenuEn } from '@shared/data/data';
import { ValueGlobalService } from '@services/global/value.global.service';
import { BrowserStorageService } from '@services/global/browser.storage.service';

@Component({
    selector: 'app-header',
    standalone: true,
    imports: [CommonModule, RouterLink, RouterLinkActive, NgOptimizedImage],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
})
export class HeaderComponent {
    @ViewChild('menuMobile') menuMobile!: ElementRef;

    readonly #browserStorageService = inject(BrowserStorageService);
    readonly #valueGlobalService = inject(ValueGlobalService);
    readonly localeDefaultService = this.#valueGlobalService.locale;

    menu: ItemMenu[] = MenuEn;

    openMenuMobile: boolean = false;
    showScroll: boolean = false;
    currentPosition: any;

    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        let pos =
            (document.documentElement.scrollTop || document.body.scrollTop) +
            document.documentElement.offsetHeight;
        if (pos > this.currentPosition) {
            this.showScroll = true;
        } else {
            this.showScroll = false;
        }
        this.currentPosition = pos;
    }

    constructor( @Inject(PLATFORM_ID) private platformId: Object, private location: Location) {}

    ngOnInit(): void {
        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        //Add 'implements OnInit' to the class.
        console.log(this.localeDefaultService());
        
    }

    setLocale(code: string) {
        this.#browserStorageService.setItem(
            'language',
            code
        );
        this.#valueGlobalService.setLocaleDefault(code);
        
        window.location.reload();
        // if (isPlatformBrowser(this.platformId)) {
        //     // location.reload();
        //     // window.location.reload();
        // }
    }

    openTabMenu() {
        this.openMenuMobile = !this.openMenuMobile;
        if (!!this.menuMobile) {
            if (this.menuMobile.nativeElement.style.left === '0px') {
                this.menuMobile.nativeElement.style.left = '-300px';
            } else {
                this.menuMobile.nativeElement.style.left = '0px';
            }
        }
    }

    closeTab() {
        this.openMenuMobile = !this.openMenuMobile;
        this.menuMobile.nativeElement.style.left = '-300px';
    }
}
